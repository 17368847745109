import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isAxiosError } from 'axios';

import { ContainerWithImage, ErrorBar } from '../../components';

import api from '../../services/api';

import { useUserIdsContext } from '../../contexts/UserIds';
import { useVehiclesContext } from '../../contexts/Vehicles';

import staticText from '../../localization/connect';
import { useAuthContext } from '../../contexts/Auth';

export const _getWindowOptions = () => {
  const windowSettings = {
    width: window.screen.width * 0.3,
    height: window.screen.height * 0.75,
  };

  const widthOffset = (window.outerWidth - windowSettings.width) / 2;
  const heightOffset = (window.outerHeight - windowSettings.height) / 8;

  let options = '';
  options += `top=${window.screenY + heightOffset},`;
  options += `left=${window.screenX + widthOffset},`;
  options += `width=${windowSettings.width},`;
  options += `height=${windowSettings.height},`;

  return options;
};

const Connect = () => {
  const [connectUrlLoading, setConnectUrlLoading] = useState(false);
  const [connectError, setConnectError] = useState('');
  const [vehiclesLoading, setVehiclesLoading] = useState(false);
  const [disableLaunchButton, setDisableLaunchButton] = useState(false);
  const { userIds, setUserIds } = useUserIdsContext();
  const { vehicles, setVehicles, setSelectedVehicleId } = useVehiclesContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  /* istanbul ignore next */
  const handleMessage = event => {
    // bail if message from unexpected source
    if (!event.origin.startsWith(window.location.origin)) {
      return;
    }

    const message = event.data || {};
    // bail if `message.name` is not `SmartcarAuthMessage`
    // this prevents attempting to handle messages intended for others
    if (message.name !== 'MySmartcarAuthMessage') {
      return;
    }

    if (message.user_id) {
      // check for valid uuid for the user_id
      if (
        new RegExp(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
        ).test(message.user_id)
      ) {
        // set user id in context for future use
        setUserIds({
          ...userIds,
          [message.user_id]: new Date().toISOString(),
        });
        // fetch vehicles
        setVehiclesLoading(true);
        getVehicles(message.user_id);
      }
    } else if (message.error) {
      setConnectError(
        staticText.errorMap[message.error] || staticText.connectGenericError,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  /* istanbul ignore next */
  const getConnectUrl = async () => {
    try {
      setConnectError('');
      setConnectUrlLoading(true);
      setDisableLaunchButton(true);

      const result = await api.getConnectUrl();

      setConnectUrlLoading(false);
      const popupWindow = window.open(
        result.data.connectUrl,
        'Connect your car',
        _getWindowOptions(),
      );
      if(!popupWindow){
        setConnectError(staticText.popupBlocked);
        setTimeout(() => {
          setDisableLaunchButton(false);
        }, 5000);
      }
      setDisableLaunchButton(false);
    } catch (error) {
      setConnectUrlLoading(false);
      if (isAxiosError(error)) {
        setConnectError(staticText.connectGenericError);
      }
    }
  };

  /* istanbul ignore next */
  const getVehicles = async (userId: string) => {
    try {
            // userId is used to pull vehicles, but user is for Sentry on backend
      const result = await api.getVehicles(userId, user);
      const selectedVehicleId = Object.keys(result.data)[0];

      setVehicles({
        ...vehicles,
        ...result.data,
      });
      setSelectedVehicleId(selectedVehicleId);
      setVehiclesLoading(false);
      navigate(`/vehicles/${selectedVehicleId}`);
    } catch (error) {
      if (isAxiosError(error)) {
        setConnectError(staticText.connectGenericError);
      }
      setVehiclesLoading(false);
    }
  };

    /* istanbul ignore next */
  const handleErrorClose = () => {
    setConnectError('');
    setDisableLaunchButton(false);
  };

    /* istanbul ignore next */
  const handleConnectButtonClick = async () => {
    await getConnectUrl();
  };

  return (
    <>
      <Helmet>
        <title>{staticText.pageTitle}</title>
        <meta name={staticText.pageName} content={staticText.pageContent} />
      </Helmet>
      {vehiclesLoading ? (
        <div className="page-spinner">
          <div className="spinner" />
        </div>
      ) : (
        <>
          {connectError && (
            <div className="connect-error-container">
              <ErrorBar
                errorMessage={connectError}
                handleClose={handleErrorClose}
              />
            </div>
          )}
          <ContainerWithImage>
            <div className="connect-text-box">
              <h1 className="m-b-xs">{staticText.title}</h1>
              <p className="m-none">{staticText.body}</p>
            </div>
            <button
              type="button"
              className="container-with-image-button"
              onClick={handleConnectButtonClick}
              disabled={disableLaunchButton || connectError.length < 0}
            >
              {connectUrlLoading ? (
                <div className="spinner" />
              ) : (
                staticText.buttonText
              )}
            </button>
          </ContainerWithImage>
        </>
      )}
    </>
  );
};

export default Connect;
