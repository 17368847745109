export default {
  pageTitle: 'mySmartcar - Vehicles',
  pageDescription:
    'Manage applications connected to your vehicle with Smartcar',
  appsList: {
    header: 'Connected Apps',
    headerSubtext:
      'These apps are connected to your vehicle through Smartcar. You can view and manage access by selecting an app below.',
    accessGranted: 'Access granted on ',
    noCarData: "No apps using this car's data",
  },
  appPanel: {
    back: 'Back to apps',
    header: 'Permissions & Activity',
    headerSubtext: (appName: string, make: string) =>
      `You granted ${appName} permission to access your ${make} account on`,
    revokeAccessBtn: 'Revoke access',
    noApps: {
      header: 'You haven’t connected this car to any apps yet',
      subtext:
        'When you connect your car to third-party apps that use Smartcar, you’ll see the permissions they can access here.',
    },
    // share with Connect translations when we lokalize this app
    permissions: {
      control_charge: 'Start & stop charging',
      control_climate: 'Control climate',
      control_ignition: 'Control ignition',
      control_navigation: 'Control Navigation',
      control_pin: 'Create or delete pin to drive feature',
      control_security: 'Lock & unlock',
      read_alerts: 'Read alerts',
      read_battery: 'EV battery level',
      read_charge: 'Charging status',
      read_charge_events: 'Read charge events',
      read_charge_locations: 'Read charge locations',
      read_charge_records: 'Read charge records',
      read_climate: 'Read climate',
      read_compass: 'Read compass',
      read_engine_oil: 'Engine oil life',
      read_extended_vehicle_info: 'Detailed vehicle info',
      read_fuel: 'Fuel tank level',
      read_location: 'Location',
      read_odometer: 'Odometer',
      read_security: 'Lock status',
      read_service_history: 'Read service history',
      read_speedometer: 'Read speedometer',
      read_thermometer: 'Read thermometer',
      read_tires: 'Tire pressure',
      read_user_profile: 'Profile information',
      read_vehicle_info: 'Make, model, year',
      read_vin: 'VIN',
    },
    permissionLastAccess: 'granted',
    revokeAccessSuccess: companyName => {
      return `You\'ve revoked access to ${companyName}. Visit ${companyName} to reconnect your car. `;
    },
  },
};
