import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Footer from '../Footer';
import Topbar from '../Topbar';

import { useVehiclesContext } from '../../contexts/Vehicles';
import { useAuthContext } from '../../contexts/Auth';

const Layout = () => {
  const navigate = useNavigate();
  const { vehicleId = '' } = useParams();
  const { user } = useAuthContext();
  const { vehicles, selectedVehicleId, setSelectedVehicleId } =
    useVehiclesContext();

  const vehicleIds = vehicles ? Object.keys(vehicles) : [];

  useEffect(() => {
    if (!user) {
      return navigate('/login');
    } else if (!vehicles || vehicleIds.length < 1) {
      return navigate('/connect');
    } else if (!vehicleId) {
      const newVehicleId = vehicleIds[0];
      setSelectedVehicleId(newVehicleId);
      return navigate(`/vehicles/${newVehicleId}`);
    } else if (
      vehicleId !== selectedVehicleId &&
      vehicleIds.includes(vehicleId)
    ) {
      return setSelectedVehicleId(vehicleId);
    }
    return navigate(`/vehicles/${selectedVehicleId}`)
  }, []);

  return (
    <>
      <Topbar />
      <Outlet />
      <Footer isAuthenticated={Boolean(user)} />
    </>
  );
};

export default Layout;
