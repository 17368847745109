import { createContext, useContext } from 'react';

import useStorage from '../hooks/useStorage';

import { IUserIdsContext } from '../interfaces/contexts/userIds.interface';
import { IProps } from '../interfaces/contexts/shared.interface';

export const UserIdsContext = createContext<IUserIdsContext | undefined>(undefined);

const useUserIdsProvider = () => {
  const [userIds, setUserIds] = useStorage({
    storageType: 'sessionStorage',
    key: 'userIds',
    defaultValue: null,
  });

  const clearUserIds = () => {
    setUserIds(null);
  }

  return {
    userIds,
    setUserIds,
    clearUserIds
  };
};

export const UserIdsProvider = ({ children }: IProps) => {
  const userIdsValue = useUserIdsProvider();

  return (
    <UserIdsContext.Provider value={userIdsValue}>
      {children}
    </UserIdsContext.Provider>
  );
};

export const useUserIdsContext = () => {
  const context = useContext(UserIdsContext);

  if (context === undefined) {
    throw new Error('useUserIdsContext must be used within UserIdsProvider');
  }

  return context;
};
