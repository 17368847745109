/* istanbul ignore file */
const Success = () => {
  if (!window.opener) {
    throw new Error('window.opener must be defined');
  }

  const params = new URLSearchParams(window.location.search);
  const origin = window.location.origin;

  const message = {
    name: 'MySmartcarAuthMessage',
    user_id: params.get('user_id'),
    error: params.get('error'),
  };

  window.opener.postMessage(message, origin);
  window.close();

  return <div className="page-spinner"><div className="spinner" /></div>
};

export default Success;
