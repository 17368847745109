import { useState } from 'react';

import { IProps as IApplication } from '../../interfaces/components/AppPanel.interface';
import { renderAppLogo } from '../AppsList/components/AppsListItem/AppsListItem';
import { RevokeAccessModal, Toast } from '..';
import {
  AlertIcon,
  BatteryIcon,
  CarIcon,
  ChargeIcon,
  ChargeLocationsIcon,
  ChargeRecordsIcon,
  CompassIcon,
  ControlChargeIcon,
  ControlClimateIcon,
  ControlIgnitionIcon,
  ExtendedVehicleInfoIcon,
  FuelIcon,
  HashIcon,
  MapPinIcon,
  OdometerIcon,
  OilIcon,
  PinIcon,
  RecordsIcon,
  SecurityIcon,
  SmartcarIcon,
  SpeedometerIcon,
  TiresIcon,
  ThermometerIcon,
  UserIcon,
} from '../svg/icons';

import staticText from '../../localization/vehicle';

import { extractParenthesesContent, transformTimestamp } from '../../utils';
import { useVehiclesContext } from '../../contexts/Vehicles';

const permissionIconMap = {
  control_charge: <ControlChargeIcon />,
  control_climate: <ControlClimateIcon />,
  control_ignition: <ControlIgnitionIcon />,
  control_navigation: <MapPinIcon />,
  control_pin: <PinIcon />,
  control_security: <SecurityIcon />,
  read_alerts: <AlertIcon />,
  read_battery: <BatteryIcon />,
  read_charge: <ChargeIcon />,
  read_charge_events: <ChargeIcon />,
  read_charge_locations: <ChargeLocationsIcon />,
  read_charge_records: <ChargeRecordsIcon />,
  read_climate: <ThermometerIcon />,
  read_compass: <CompassIcon />,
  read_engine_oil: <OilIcon />,
  read_extended_vehicle_info: <ExtendedVehicleInfoIcon />,
  read_fuel: <FuelIcon />,
  read_location: <MapPinIcon />,
  read_odometer: <OdometerIcon />,
  read_security: <SecurityIcon />,
  read_service_history: <RecordsIcon />,
  read_speedometer: <SpeedometerIcon />,
  read_thermometer: <ThermometerIcon />,
  read_tires: <TiresIcon />,
  read_user_profile: <UserIcon />,
  read_vehicle_info: <CarIcon />,
  read_vin: <HashIcon />,
  fallback: <SmartcarIcon />,
};

const AppPanel = (app: IApplication) => {
  const { vehicles, selectedVehicleId } = useVehiclesContext();
  const { 
    info: {
      make
    } 
  } = vehicles[selectedVehicleId];

  const noApps = !Boolean(app.id);
  const twoColumns = !noApps && app.permissions.length > 10;

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRevokeButtonClick = () => {
    setModalOpen(true);
  };

  const renderAppAccessGrantedAt = () => {
    const text = staticText.appPanel.headerSubtext(app.name, make);
    const date = transformTimestamp(app.accessGrantedAt);
  const timezone = extractParenthesesContent(app.accessGrantedAt);
    return `${text} ${date} ${timezone}`;
  };

  return (
    <div className="app-panel">
      {noApps ? (
        <div className="app-panel-empty">
          <img
            src="/assets/images/graphics/smartcar-connect-illustration.svg"
            alt=""
          />
          <h3>{staticText.appPanel.noApps.header}</h3>
          <p>{staticText.appPanel.noApps.subtext}</p>
        </div>
      ) : (
        <>
          <div className="app-panel-header">
            <div>{renderAppLogo(app.logoUrl, app.name)}</div>
            <h2>{app.name}</h2>
          </div>
          <div className="app-panel-permissions">
            <h3>{staticText.appPanel.header}</h3>
            <p className="small">{renderAppAccessGrantedAt()}</p>
            <ul
              className={`permissions-list ${twoColumns ? 'two-column' : ''}`}
            >
              {app.permissions.map(permissionData => {
                const { name } = permissionData;
                return (
                  <li key={name} className="permissions-list-item">
                    <div>
                      <div className="permissions-list-item-icon">
                        {permissionIconMap[name] || permissionIconMap.fallback}
                      </div>
                      <p className="permissions-list-item-name">
                        {staticText.appPanel.permissions[name] || name}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <button
            className="revoke-access"
            type="button"
            onClick={handleRevokeButtonClick}
            disabled={success}
          >
            {staticText.appPanel.revokeAccessBtn}
          </button>
          {modalOpen && (
            <RevokeAccessModal
              setModalOpen={setModalOpen}
              setSuccess={setSuccess}
              setError={setError}
              appName={app.name}
              appIcon={app.logoUrl}
              applicationId={app.id}
            />
          )}
          {error && (
            <Toast
              message={'Unable to revoke permission access. Please try again.'}
              type="warn"
            />
          )}
          {success && !error && (
            <Toast
              message={staticText.appPanel.revokeAccessSuccess(app.name)}
              type="success"
            />
          )}
        </>
      )}
    </div>
  );
};

export default AppPanel;
