import { Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Connect, Login, Success, Vehicle } from './pages';
import { Layout, ProtectedRoute } from './components';

import { AuthProvider } from './contexts/Auth';
import { VehiclesProvider } from './contexts/Vehicles';
import { UserIdsProvider } from './contexts/UserIds';

import staticText from './localization/app';

const App = () => {
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>{staticText.pageTitle}</title>
          <meta name="description" content={staticText.pageDescription} />
        </Helmet>
        <UserIdsProvider>
          <VehiclesProvider>
            <AuthProvider>
              <Routes>
                {/* This page is what Connect redirects to in the popup, so it needs to be outside
                  of the layout component so we don't duplicate the topbar */}
                <Route path="/success" element={<Success />} />
                <Route element={<Layout />}>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/connect"
                    element={
                      <ProtectedRoute>
                        <Connect />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/vehicles/:vehicleId"
                    element={
                      <ProtectedRoute>
                        <Vehicle />
                      </ProtectedRoute>
                    }
                  />
                  {/* Redirect to vehicles/login page for unsupported routes */}
                  <Route
                    path="/*"
                    element={
                      <ProtectedRoute>
                        <Vehicle />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Routes>
            </AuthProvider>
          </VehiclesProvider>
        </UserIdsProvider>
      </>
    </HelmetProvider>
  );
};

export default App;
