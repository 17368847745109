import VehicleMenu from '../VehicleMenu';
import { IProps } from '../../../../interfaces/components/MobileMenu.interface';
import LogoutButton from '../LogoutButton';

const MobileMenu = ({ accounts, toggleMenu }: IProps) => {
  return (
    <div className="mobile-menu">
      <VehicleMenu accounts={accounts} toggleMenu={() =>toggleMenu('mobile')} />
      <div className="p-xs">
        <LogoutButton toggleMenu={() => toggleMenu('mobile')} />
      </div>
    </div>
  );
};

export default MobileMenu;
