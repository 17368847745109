import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import { AppPanel, AppsList } from '../../components';
import { useVehiclesContext } from '../../contexts/Vehicles';

import { ChevronIcon } from '../../components/svg/icons';
import staticText from '../../localization/vehicle';
import useStorage from '../../hooks/useStorage';
import useIsMobile from '../../hooks/useIsMobile';

const Vehicle = () => {
  const navigate = useNavigate();
  const { vehicleId = '' } = useParams();
  const { vehicles, selectedVehicleId, setSelectedVehicleId } =
    useVehiclesContext();
  const [isMobile] = useIsMobile();

  let currentVehicleId =
    vehicleId || selectedVehicleId || Object.keys(vehicles)[0];

  useEffect(() => {
    if (!vehicles || Object.keys(vehicles).length < 1) {
      navigate('/connect');
    } else if (
      vehicleId !== selectedVehicleId &&
      Object.keys(vehicles).includes(vehicleId)
    ) {
      setSelectedVehicleId(vehicleId);
    }
  }, [vehicleId]);

  const [applications, setApplications] = useState(
    vehicles[currentVehicleId]?.applications || {},
  );
  const [selectedAppId, setSelectedAppId] = useStorage({
    storageType: 'sessionStorage',
    key: 'selectedAppId',
    defaultValue: Object.keys(applications)[0] || '',
  });

  const handleBackClick = () => {
    setSelectedAppId('');
  };

  useEffect(() => {
    if (isMobile) {
      setSelectedAppId('');
    } else {
      setSelectedAppId(Object.keys(applications)[0]);
    }
  }, [isMobile]);

  useEffect(() => {
    const selectedVehicleApps = vehicles[selectedVehicleId]?.applications || {};
    setApplications(selectedVehicleApps);
    if (Object.keys(selectedVehicleApps).length > 0) {
      setSelectedAppId(Object.keys(selectedVehicleApps)[0]);
    }

    return () => {
      setSelectedAppId('');
    }
  }, [selectedVehicleId, vehicles]);

  return (
    <>
      <Helmet>
        <title>{staticText.pageTitle}</title>
        <meta name="description" content={staticText.pageDescription} />
      </Helmet>
      <div className="vehicle">
        {isMobile && !selectedAppId && (
          <AppsList
            applications={applications}
            selectedAppId={selectedAppId}
            setSelectedAppId={setSelectedAppId}
          />
        )}
        {isMobile && selectedAppId && (
          <>
            <button
              className="back-btn"
              type="button"
              onClick={handleBackClick}
            >
              <ChevronIcon />
              {staticText.appPanel.back}
            </button>
            <AppPanel {...applications[selectedAppId]} />
          </>
        )}
        {!isMobile && (
          <>
            <AppsList
              applications={applications}
              selectedAppId={selectedAppId}
              setSelectedAppId={setSelectedAppId}
            />
            <AppPanel {...applications[selectedAppId]} />
          </>
        )}
      </div>
    </>
  );
};

export default Vehicle;
