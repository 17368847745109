import { useState } from 'react';
import { IProps } from '../interfaces/hooks/useStorage.interface';

const useStorage = ({ storageType, key, defaultValue }: IProps) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window[storageType].getItem(key);
      if (value) {
        return JSON.parse(value);
      }
      window[storageType].setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window[storageType].setItem(key, JSON.stringify(newValue));
    } catch (err) {
      console.log(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export default useStorage;
