import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IProps } from '../../interfaces/components/Toast.interface';

import { 
  AlertTriangleIconYellow, 
  CheckCircleIcon, 
  InfoIcon,
  AlertTriangleIconRed
 } from '../../components/svg/icons'; 

const generateIcon = (type) => {
  switch (type) {
    case 'warn':
      return <AlertTriangleIconRed />
    case 'success':
      return <CheckCircleIcon />
    case 'alert':
      return <AlertTriangleIconYellow />
    case 'info':
    default:
      return <InfoIcon />
  }
};

// todo: refactor to use css classnames instead of hard coding it here
// determines background color
const getBackgroundColor = (type) => {
  const defaultBorder = `1px solid`
  switch(type){
    case 'warn':
      return { backgroundColor: '#ffdad6', border: `${defaultBorder} #ba1a1a`};
    case 'success':
      return { backgroundColor: '#27AE6033', border: `${defaultBorder} #27AE60`};
    case 'alert':
      return  { backgroundColor: '#fce492', border: `${defaultBorder} #ffe17b`};
    default:
      return { backgroundColor: '#ffffff', border: `${defaultBorder} #00819d`};
  }
}

const Toast = ({message, type = ''}: IProps) => {
  const {backgroundColor, border} = getBackgroundColor(type);

  const content = (
    <div className='feedback'>
      <div className='feedback-icon'>
        {generateIcon(type)}
      </div>
      <span className='feedback-message'>{message}</span>
    </div>
  );

  useEffect(() => {
    const notify = () => {toast(content, {
      style: { 
        background: backgroundColor,
        border: border
       }
    })};

    notify();
  }, []);

  return (
    <div>
      <ToastContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'auto' 
        }}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        closeOnClick
        rtl={false}
      />
    </div>
  );
}

export default Toast;
