import { LogoutIcon } from '../../../svg/icons';
import staticText from '../../../../localization/app';
import { IProps } from '../../../../interfaces/components/LogoutButton.interface';
import { useAuthContext } from '../../../../contexts/Auth';

const LogoutButton = ({ toggleMenu }: IProps) => {
  const { logout } = useAuthContext();

  const handleLogout = () => {
    toggleMenu();
    logout();
  };
  return (
    <button type="button" className="logout-btn" onClick={() => {handleLogout()}}>
      <div>{staticText.topbar.logout}</div>
      <LogoutIcon />
    </button>
  );
};

export default LogoutButton;
