import IProps from '../../interfaces/components/Footer.interface';
import staticText from '../../localization/app';

const Footer = ({ isAuthenticated }: IProps) => {
  return (
    <footer className="footer">
      <div className="links">
        <p className="copyright">
          {`© Smartcar, Inc. ${new Date().getFullYear()}`}
        </p>
        <a href="https://smartcar.com/privacy" className="link">
          {staticText.footer.privacy}
        </a>
        <a href="mailto:support@smartcar.com" className="link">
          {staticText.footer.support}
        </a>
      </div>
      {isAuthenticated ? (
        <p className="disclaimer">{staticText.footer.disclaimer}</p>
      ) : null}
    </footer>
  );
};

export default Footer;
