import smartcarLoginGraphic from '/assets/images/graphics/smartcar-login.png';

const ContainerWithImage = ({ children }) => {

  return (
    <div className="container-with-image">
      <div className="container-with-image-box">
        {children}
      </div>
      <div className="container-with-image-image-container">
        <img
          className="container-with-image-image"
          src={smartcarLoginGraphic}
          alt="car connected with smartcar"
        />
      </div>
    </div>
  );

};

export default ContainerWithImage;
