import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { ContainerWithImage } from '../../components';

import staticText from '../../localization/login';
import { useAuthContext } from '../../contexts/Auth';
import { isAxiosError } from 'axios';

const Login = () => {
  const { login, verifyCode, resendVerificationCode } = useAuthContext();
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);

  /* istanbul ignore next */
  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    showCodeInput ? setCode(value) : setUsername(value);
  }

  /* istanbul ignore next */
  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setLoginLoading(true);

    try {
      if (!showCodeInput) {
        const result = await login(username);

        if (result && result.message === 'success') {
          setShowCodeInput(true);
        }
        setLoginLoading(false);
      } else {
        await verifyCode(username, code);
        setLoginLoading(false);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        setLoginError(staticText.loginGenericError);
      }
    }
    setLoginLoading(false);
  }

      /* istanbul ignore next */
  const resendCode = async () => {
    if(codeResent) return;

    try {
      const result = await resendVerificationCode(username);

      if (result && result.message === 'successfully resent code') {
        setCodeResent(true);
      }
      setLoginLoading(false);
    } catch(error){
      setLoginLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCodeResent(false);
    }, 4000);
  }, [codeResent]);

  return (
    <>
      <Helmet>
        <title>{staticText.meta.title}</title>
      </Helmet>
      <>
        <ContainerWithImage>
          <h1>{showCodeInput ? staticText.codeTitle : staticText.title}</h1>
          <p className="m-t-none m-b-med">
            {showCodeInput
              ? staticText.codeDescription(username)
              : staticText.description}
          </p>
          <form className="login-form" onSubmit={handleSubmit}>
            <label htmlFor="email-input">
              <span className="login-email-text">
                {showCodeInput ? staticText.code : staticText.email}
              </span>
            </label>
            <input
              id="email-input"
              className="login-input"
              type={showCodeInput ? "text" : "email"}
              value={showCodeInput ? code : username}
              maxLength={showCodeInput ? 6 : 60}
              onChange={handleChange}
              required
            />

            {loginLoading ? (
              <div className="page-spinner">
                <div className="spinner" />
              </div>
            ) : (
              <div>
                {loginError && (
                  <p className="login-error">{staticText.loginGenericError}</p>
                )}
                {showCodeInput && !codeResent && (
                  <button
                    type="button"
                    onClick={() => resendCode()}
                    className={`resend-button`}
                  >
                    {staticText.resend}
                  </button>
                )}
                {showCodeInput && codeResent && (
                  <p className="code-resent">{staticText.codeResent}</p>
                )}
                <button
                  type="submit"
                  className="container-with-image-button"
                  disabled={showCodeInput ? code.length !== 6 : !username}
                >
                  {showCodeInput ? staticText.continue : staticText.login}
                </button>
              </div>
            )}
          </form>
        </ContainerWithImage>
      </>
    </>
  );
};

export default Login;
