import AppsListItem from './components/AppsListItem/AppsListItem';
import { IProps } from '../../interfaces/components/AppsList.interface';
import staticText from '../../localization/vehicle';

const AppsList = ({
  applications,
  selectedAppId,
  setSelectedAppId,
}: IProps) => {
  const appIds = Object.keys(applications);
  return (
    <div className="apps-list">
      <div className="apps-list-header">
        <h1>{staticText.appsList.header}</h1>
        <p>{staticText.appsList.headerSubtext}</p>
      </div>
      {appIds.length < 1 && (
        <p className="apps-list-empty">{staticText.appsList.noCarData}</p>
      )}
      <div className='apps-list-container'>
        {appIds.length > 0 &&
          appIds.map(appId => {
            const app = applications[appId];
            return (
              <AppsListItem
                {...app}
                key={app.id}
                setSelectedAppId={setSelectedAppId}
                active={app.id === selectedAppId}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AppsList;
