import { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import useStorage from '../hooks/useStorage';

import { IAuthContext } from '../interfaces/contexts/auth.interface';
import { IProps } from '../interfaces/contexts/shared.interface';

import { useVehiclesContext } from './Vehicles';
import api from '../services/api';
import { useUserIdsContext } from './UserIds';

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

const useAuthProvider = () => {
  const navigate = useNavigate()
  const [user, setUser] = useStorage({
    storageType: 'sessionStorage',
    key: 'user',
    defaultValue: null,
  });
  const { clearVehicles } = useVehiclesContext();
  const { clearUserIds } = useUserIdsContext();

  const login = async (username: string) => {
    return await api.login(username);
  };

  const verifyCode = async (username: string, code: string) => {
    const result = await api.verifyCode(username, code);
    setUser(result.data.email);
    navigate('/connect');
  };

  const resendVerificationCode = async (username: string) => {
    const result = await api.resendVerificationCode(username);
    return result.data;
  };

  const logout = () => {
    api.logout();
    setUser('');
    clearVehicles();
    clearUserIds();
    
    navigate('/login');
  };

  return {
    user,
    login,
    verifyCode,
    resendVerificationCode,
    logout,
  };
};

export const AuthProvider = ({ children }: IProps) => {
  const authValue = useAuthProvider();

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within AuthProvider');
  }

  return context;
};
