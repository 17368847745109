export default {
  pageTitle: 'mySmartcar',
  pageDescription:
    'Manage applications connected to your vehicle with Smartcar',
  footer: {
    privacy: 'Privacy',
    support: 'Support',
    disclaimer:
      'All logos and brand names are property of their respective owners, and the use does not imply endorsement of or affiliation with Smartcar.',
  },
  topbar: {
    addVehicle: 'Add vehicle(s)',
    logout: 'Log out',
  },
};
