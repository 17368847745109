import { ReactComponent as CloseIcon } from '../../components/svg/icons/close.svg';
import { ReactComponent as ErrorIcon } from '../../components/svg/icons/error.svg';

import IProps from '../../interfaces/components/ErrorBar.interface';

const ErrorBar = ({ errorMessage, button, handleClose }: IProps) => {
  return (
    <div className="error-container">
      <ErrorIcon />
      <div className="error-message">{errorMessage}</div>
      {button && (
        <button type="button" onClick={button.handleClick}>{button.text}</button>
      )}
      <button type="button" onClick={handleClose} className="error-close-button">
        <CloseIcon />
      </button>
    </div >
  )

};

export default ErrorBar;
