import IProps from '../../../../interfaces/components/AccountMenu.interface';
import LogoutButton from '../LogoutButton';

const AccountMenu = ({ toggleMenu }: IProps) => {
  return (
    <div className="p-xs">
      <LogoutButton toggleMenu={toggleMenu} />
    </div>
  );
};

export default AccountMenu;
