import { IProps } from "../../interfaces/components/Modal.interface";


const Modal = ({ children, closeModal }: IProps) => {
 return(
  <div onClick={closeModal} className="modal-wrapper">
      <div onClick={(e) => e.stopPropagation()} className="modal">
        {children}
      </div>
    </div>
 ) 
}

export default Modal;
