import { IProps } from '../../../../interfaces/components/AppsListItem.interface';
import { ChevronIcon } from '../../../svg/icons';
import { transformTimestamp } from '../../../../utils';
import staticText from '../../../../localization/vehicle';

export const renderAppLogo = (logoUrl: any, name: string) => (
  <div className="application-logo">
    {logoUrl ? (
      <img src={logoUrl} alt={`${name} logo`} />
    ) : (
      <div className="application-logo-fallback">
        <span>{name.slice(0, 2)}</span>
      </div>
    )}
  </div>
);

const AppsListItem = ({
  id,
  name,
  logoUrl,
  accessGrantedAt,
  active,
  setSelectedAppId,
}: IProps) => {
  const handleClick = (id: string) => {
    setSelectedAppId(id);
  };

  const renderAppAccessGrantedAt = () => {
    const text = staticText.appsList.accessGranted;
    const date = transformTimestamp(accessGrantedAt);
    return `${text} ${date}`;
  };
  return (
    <button
      id={id}
      type="button"
      className={`apps-list-item ${active ? 'active' : ''}`}
      onClick={() => handleClick(id)}
    >
      <div className="apps-list-item-info">
        {renderAppLogo(logoUrl, name)}
        <div className="apps-list-item-text">
          <h3>{name}</h3>
          <p className="apps-list-item-timestamp">
            {renderAppAccessGrantedAt()}
          </p>
        </div>
      </div>
      <ChevronIcon className="expand-icon" />
    </button>
  );
};

export default AppsListItem;
