import { Navigate } from 'react-router-dom';

import { IProps } from '../../interfaces/components/ProtectedRoute.interface';
import { useAuthContext } from '../../contexts/Auth';

const ProtectedRoute = ({ children, redirect = '/login' }: IProps) => {
  const { user } = useAuthContext();
  
  if (!user) {
    // user is not authenticated
    return <Navigate to={redirect} />;
  }

  return children;
};

export default ProtectedRoute;
