export const getOemLogo = (make) => {
  const formattedMake = make.split(/[ -]+/).join('').toLowerCase();
  return `/assets/images/logos/${formattedMake}.svg`;
};

export const getYearModel = ({ model, year }) => {
  return ` ${year} · ${model}`;
};

export const getVehiclesByAccount = (vehicles) => {
  const vehiclesByAccount = {};
  Object.keys(vehicles).forEach(vehicleId => {
    const vehicle = vehicles[vehicleId];
    const key = `${vehicle.info.make}:${vehicle.oemUsername}`;
    vehicle.info.oemUsername = vehicle.oemUsername;
    if (vehiclesByAccount[key]) {
      vehiclesByAccount[key].push(vehicle.info);
    } else {
      vehiclesByAccount[key] = [vehicle.info];
    }
  });
  return vehiclesByAccount;
};

export function extractParenthesesContent(inputString) {
  const start = inputString.indexOf('(');
  const end = inputString.indexOf(')');

  if (start !== -1 && end !== -1) {
      return inputString.substring(start + 1, end);
  } else {
      return '';
  }
}

export const transformTimestamp = (
  timestamp,
  abbreviated,
) => {
  const dateObj = new Date(timestamp);

  // output: November 16, 2024
  const date = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  // output: 11/16/2024
  const abbreviatedDate = `${dateObj.getMonth()}/${dateObj.getDay()}/${dateObj.getFullYear()}`;

  // output: 11:15 AM
  const time = new Date(timestamp).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'});

  // output: Eastern Daylight Time
  const timezone = extractParenthesesContent(dateObj.toTimeString());

  const abbrTimezone = timezones[timezone];

  const textDate = `${ abbreviated ? abbreviatedDate : date} at ${time} ${abbrTimezone ? `(${abbrTimezone})`: ''}`;

  return textDate;
};


const timezones = {
  'Eastern Standard Time': 'EST',
  'Eastern Daylight Time': 'EDT',
  'Central Standard Time': 'CST',
  'Central Daylight Time': 'CDT',
  'Mountain Standard Time': 'MST',
  'Mountain Daylight Time': 'MDT',
  'Pacific Standard Time': 'PST',
  'Pacific Daylight Time': 'PDT',
  'Greenwich Mean Time': 'GMT',
  'British Summer Time': 'BST',
  'Indian Standard Time': 'IST',
  'Australian Eastern Standard Time': 'AEST',
  'Australian Eastern Daylight Time': 'AEDT',
  'Japan Standard Time': 'JST',
  'Central European Time': 'CET',
  'Central European Summer Time': 'CEST',
  'New Zealand Standard Time': 'NZST',
  'New Zealand Daylight Time': 'NZDT',
  'Alaska Standard Time': 'AKST',
  'Alaska Daylight Time': 'AKDT',
  'China Standard Time': 'CST',
  'Hong Kong Time': 'HKT',
  'Korea Standard Time': 'KST',
  'Singapore Standard Time': 'SGT',
  'Atlantic Standard Time': 'AST',
  'Atlantic Daylight Time': 'ADT',
  'Hawaii-Aleutian Standard Time': 'HST',
  'Hawaii-Aleutian Daylight Time': 'HDT',
  'West Africa Time': 'WAT',
  'East Africa Time': 'EAT',
  'Central Africa Time': 'CAT',
  'Israel Standard Time': 'IST',
  'Argentina Time': 'ART',
  'Brasilia Time': 'BRT',
  'Venezuelan Standard Time': 'VET',
  'Chamorro Standard Time': 'CHST'
};


