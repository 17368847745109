import axios, { AxiosInstance, AxiosResponse } from 'axios';

import appConfig from 'appConfig';

import { IApi } from '../../interfaces/services/api';

const instance: AxiosInstance = axios.create({
  baseURL: appConfig.baseUrl,
  // allows cookies to be sent
  withCredentials: true,
});

const api: IApi = {
  login: async (username) => {
    const response: AxiosResponse = await instance.post('/login', { username });

    return response.data;
  },

  verifyCode: async (username, code) => {
    const response: AxiosResponse = await instance.post('/verify-code', {
      code,
      email: username,
    });

    return response;
  },

  getConnectUrl: async () => {
    const response = await instance.get('/connect/url');

    return response;
  },

  getVehicles: async (userId, user) => {
    const response = await instance.get(`/vehicles/${userId}`, {
      params: {
        username: user,
      }
    });

    return response;
  },

  postRevokeAccess: async (applicationId, vehicleId, user) => {
    const response = await instance.delete('/revoke', {
      params: {
        applicationId,
        vehicleId,
        username: user,
      },
    });

    return response;
  },

  resendVerificationCode: async (username) => {
    const response = await instance.post('/resend-code', {
      email: username
    });
    return response;
  },

  logout: async () => {
    const response = await instance.post('/logout');

    return response;
  },
  axiosInstance: instance
};

export default api;
