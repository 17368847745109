export default {
  meta: {
    title: 'mySmartcar - Login',
  },
  title: 'Welcome to mySmartcar',
  codeTitle: 'We sent you a code',
  description:
    'View and manage the applications that have access to your vehicle. Log in with your email to get started.',
  codeDescription: (username) => `We sent a code to ${username}. It may take up to 30 seconds to arrive.`,
  login: 'Log in',
  email: 'Email',
  code: 'Code',
  continue: 'Continue',
  loginGenericError: 'Incorrect code',
  resend: 'Resend code',
  codeResent: 'Code resent. Please check your email'
};
