import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { AccountMenu, VehicleMenu, MobileMenu } from './components';
import { AvatarIcon, ChevronIcon, SmartcarLogo } from '../svg/icons';
import { getOemLogo, getVehiclesByAccount } from '../../utils';

import { useAuthContext } from '../../contexts/Auth';
import { useVehiclesContext } from '../../contexts/Vehicles';
import useIsMobile from '../../hooks/useIsMobile';

const Topbar = () => {
  const { user } = useAuthContext();
  const { vehicles, selectedVehicleId } = useVehiclesContext();

  const vehicleIds = Object.keys(vehicles);
  let showVehicleMenu = vehicleIds.length > 0;

  const selectedVehicle = vehicles[selectedVehicleId];

  const [isMobile] = useIsMobile();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [vehicleMenuOpen, setVehicleMenuOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  /* istanbul ignore next */
  const toggleMenu = (type: string) => {
    if (type === 'mobile') {
      setMobileMenuOpen(!mobileMenuOpen);
    } else if (type === 'vehicle') {
      setVehicleMenuOpen(!vehicleMenuOpen);
    } else if (type === 'account') {
      setAccountMenuOpen(!accountMenuOpen);
    }
  };

  const vehicleMenuRef = useRef<HTMLDivElement>(null);
  const accountMenuRef = useRef<HTMLDivElement>(null);

  /* istanbul ignore next */
  const handleMenuClick = e => {
    if (
      vehicleMenuRef &&
      vehicleMenuRef.current &&
      !vehicleMenuRef.current.contains(e.target)
    ) {
      setVehicleMenuOpen(false);
    }
    if (
      accountMenuRef &&
      accountMenuRef.current &&
      !accountMenuRef.current.contains(e.target)
    ) {
      setAccountMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleMenuClick, { capture: true });
    return () => window.removeEventListener('click', handleMenuClick);
  }, []);

  const renderVehicleDisplayNameWithLogo = ({ info }) => {
    const { make, model, year } = info;
    const modelYear = ` ${year} · ${model}`;
    return (
      <>
        <img src={getOemLogo(make)} className="oem-logo" alt="" />
        <span>
          <span className="bold">{make}</span>
          {modelYear}
        </span>
      </>
    );
  };

  const mobileNav = (
    <div className="topbar-content-mobile">
      {vehicleIds.length > 0 ? (
        <>
          <button
            type="button"
            className={`mobile-menu-button ${mobileMenuOpen ? 'open' : ''}`}
            onClick={() => toggleMenu('mobile')}
          >
            {renderVehicleDisplayNameWithLogo(selectedVehicle)}
            <ChevronIcon className="expand-icon" />
          </button>
          <div
            className={`mobile-menu-wrapper ${mobileMenuOpen ? 'open' : ''}`}
          >
            <MobileMenu
              accounts={getVehiclesByAccount(vehicles)}
              toggleMenu={toggleMenu}
            />
          </div>
        </>
      ) : (
        user && (
          <div className="relative">
            <button
              type="button"
              className={`menu-button ${accountMenuOpen ? 'open' : ''}`}
              onClick={() => toggleMenu('account')}
            >
              <div className="flex align-center m-r-sm">
                <AvatarIcon className="avatar-icon" />
                <span>{user}</span>
              </div>
              <ChevronIcon className="expand-icon" />
            </button>
            {accountMenuOpen && (
              <div
                className="dropdown-menu account-dropdown-menu"
                ref={accountMenuRef}
              >
                <AccountMenu toggleMenu={() => toggleMenu('mobile')} />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );

  return (
    <div className="topbar">
      <nav className="topbar-nav">
        <Link to="/">
          <div className="topbar-logo">
            <SmartcarLogo />
          </div>
        </Link>
        {isMobile ? (
          mobileNav
        ) : (
          <div className={`topbar-content ${!showVehicleMenu && 'no-border'}`}>
            <div>
              {showVehicleMenu &&
                (vehicleIds.length > 0 ? (
                  <div className="relative">
                    <button
                      type="button"
                      className={`menu-button ${vehicleMenuOpen ? 'open' : ''}`}
                      onClick={() => toggleMenu('vehicle')}
                    >
                      <div className="flex align-center m-r-sm">
                        {renderVehicleDisplayNameWithLogo(selectedVehicle)}
                      </div>
                      <ChevronIcon className="expand-icon" />
                    </button>
                    {vehicleMenuOpen && (
                      <div
                        className="dropdown-menu vehicle-dropdown-menu"
                        ref={vehicleMenuRef}
                      >
                        <VehicleMenu
                          accounts={getVehiclesByAccount(vehicles)}
                          toggleMenu={() => toggleMenu('vehicle')}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="single-vehicle">
                    {renderVehicleDisplayNameWithLogo(selectedVehicle)}
                  </div>
                ))}
            </div>
            {user && (
              <div className="relative">
                <button
                  type="button"
                  className={`menu-button ${accountMenuOpen ? 'open' : ''}`}
                  onClick={() => toggleMenu('account')}
                >
                  <div className="flex align-center m-r-sm">
                    <AvatarIcon className="avatar-icon" />
                    <span>{user}</span>
                  </div>
                  <ChevronIcon className="expand-icon" />
                </button>
                {accountMenuOpen && (
                  <div
                    className="dropdown-menu account-dropdown-menu"
                    ref={accountMenuRef}
                  >
                    <AccountMenu toggleMenu={() => toggleMenu('account')} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </nav>
    </div>
  );
};

export default Topbar;
