import { createContext, useContext } from 'react';

import useStorage from '../hooks/useStorage';

import { IVehiclesContext } from '../interfaces/contexts/vehicles.interface';
import { IProps } from '../interfaces/contexts/shared.interface';

export const VehiclesContext = createContext<IVehiclesContext | undefined>(undefined);

const useVehiclesProvider = () => {
  const [vehicles, setVehicles] = useStorage({
    storageType: 'sessionStorage',
    key: 'vehicles',
    defaultValue: {}
  });
  const [selectedVehicleId, setSelectedVehicleId] = useStorage({
    storageType: 'sessionStorage',
    key: 'selectedVehicleId',
    defaultValue: ''
  });

  const clearVehicles = () => {
    setVehicles({});
    setSelectedVehicleId('');
  };

  return {
    vehicles,
    setVehicles,
    selectedVehicleId,
    setSelectedVehicleId,
    clearVehicles,
  };
};

export const VehiclesProvider = ({ children }: IProps) => {
  const vehiclesValue = useVehiclesProvider();

  return (
    <VehiclesContext.Provider value={vehiclesValue}>
      {children}
    </VehiclesContext.Provider>
  );
};

export const useVehiclesContext = () => {
  const context = useContext(VehiclesContext);
  if (context === undefined) {
    throw new Error('useVehiclesContext must be used within VehiclesProvider');
  }

  return context;
};
